import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
//push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '*',
    redirect: '/exchange'
  },
  {
    name: 'exchange',
    component: () => import('./view/exchange'),
    meta: {
      title: '首页'
    }
  },
  {
    name: 'info',
    component: () => import('./view/info'),
    meta: {
      title: '会员中心'
    }
  },
  {
    name: 'res',
    component: () => import('./view/result'),
    meta: {
      title: '兑换成功'
    }
  },
  {
    name: 'record',
    component: () => import('./view/record'),
    meta: {
      title: '兑换记录'
    }
  },
  {
    name: 'detail',
    component: () => import('./view/detail'),
    meta: {
      title: '兑换详情'
    }
  },
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ mode: "history", routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
